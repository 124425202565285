import "./ErrorPage.css"

function ErrorPage() {
	return (
		<div className="errorDiv">
			<h1 className="error404">404</h1>
		</div>
	)
}

export default ErrorPage;